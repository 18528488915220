import React from 'react'
import styled from 'styled-components'
import { Check } from 'react-feather'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

export default class Thanks extends React.Component {
  componentDidMount() {}

  render() {
    const SuccessSubmissionContainer = styled.div`
      padding: 3em 0;
      text-align: center;
      max-width: 400px;
      margin: 0 auto;
    `
    const CheckContainer = styled.div`
      background: #28a33f;
      width: 60px;
      height: 60px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 50%;
      margin-bottom: 10px;
    `
    const Heading = styled.h1`
      margin-bottom: 0.25em;
    `

    let { props } = this
    let email =
      props.location && props.location.state && props.location.state.email
    let isEmail = email ? true : false
    let emailMessage = null
    if (isEmail) {
      emailMessage = 'Any additional information will be sent to ' + email + '.'
    }

    return (
      <Layout pageProps={props}>
        <Helmet title={'Thank you!'}>
          <meta name="robots" />
        </Helmet>
        <div className="container form-thank-you">
          <SuccessSubmissionContainer>
            <CheckContainer>
              <Check size={36} />
            </CheckContainer>
            <Heading>
              Thanks,{' '}
              {props.location &&
              props.location.state &&
              props.location.state.firstname
                ? props.location &&
                  props.location.state &&
                  props.location.state.firstname
                : 'Customer'}
              !
            </Heading>
            <p>We'll be in touch with you soon. {emailMessage}</p>
            <p>We look forward to exceeding your expectations!</p>
          </SuccessSubmissionContainer>
        </div>
      </Layout>
    )
  }
}
